import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("i", {
    class: _normalizeClass(
      _ctx.step >= _ctx.assignedStep
        ? 'bi btn border border-0 bi-circle-fill enable-button-pointers text-primary'
        : 'bi btn border border-0 disabled btn-light bg-white bi-circle text-primary'
    ),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.jump && _ctx.jump(...args)))
  }, null, 2))
}