
import { defineComponent } from "vue";
import EventService from "@/services/EventService";
import UserService from "@/services/UserService";
import EventsCard from "../Event/EventsCard.vue";
import { Event, Team, UserLocation } from "../../types";
import { showHasSeenUpdateUserAliasModal } from "../Modals/ModalController";
import eventBus from "../EventBus";
import {
  addListeners,
  getDeliveredNotifications,
  registerNotifications,
} from "../../capnotifications";
import { Capacitor } from "@capacitor/core";
import Wizard from "../Wizard/Wizard.vue";
import { instantiateEventAndPush } from "../Event/event";
import { getTeams, getLocations } from "@/common/common";
import { useToast, TYPE } from "vue-toastification";
import refreshMixin from "../../mixins/refreshMixin";
import { userStatusMixin } from "@/mixins/isUserLoggedInMixin";
import dayjs, { Dayjs } from "dayjs";
import HelpfulCard from "../FormComponents/HelpfulCard.vue";
import { hide } from "@popperjs/core";

export default defineComponent({
  mixins: [refreshMixin, userStatusMixin],
  components: {
    Wizard,
    EventsCard,
    HelpfulCard,
  },
  data() {
    const rightNow = Date.now();

    return {
      hideRegisterForNotificationsButton: false,
      registrationCheckComplete: false as boolean,
      isRegistered: false as boolean,
      windowWidth: window.innerWidth,
      editingAlias: false as boolean,
      userAlias: this.$store.state.userAlias?.toLowerCase(),
      wizardEventErrors: "" as string,
      wizardLocationErrors: "" as string,
      show: false as boolean,
      rightNow,
      loading: false as boolean,
      loaded: false as boolean,
      upcomingEvents: [] as Event[],
      votesEndingSoon: [] as Event[],
      teams: [] as Team[],
      locations: [] as UserLocation[],
      pastEvents: [] as Event[],
    };
  },
  mounted() {
    window.addEventListener("resize", this.updateWidth);
    this.showGuest = false;
    eventBus.$on("start", async () => {
      await this.getDashboardData();
    });
    eventBus.$on("alias-updated", () => {
      this.userAlias = this.$store.state.userAlias.toLowerCase();
    });

    if (!this.$store.state.hasSeenUpdateUserAliasWarning) {
      showHasSeenUpdateUserAliasModal();
      this.$store.commit("setHasSeenUpdateUserAliasWarning", true);
    }
    window.scrollTo(0, 0);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateWidth);
  },
  async beforeMount() {
    try {
      this.$store.dispatch("setLoading", true);
      this.$store.dispatch("setGuestWizardContext");
      this.$store.dispatch("setEventWizardContext");
      this.loading = true;
      await this.getTeams();
      await this.getLocations();
      await this.getDashboardData();
      if (Capacitor.isNativePlatform()) {
        const response = await UserService.isUserRegisteredForNotifications(
          this.$store.state.userFirebaseUid
        );
        this.isRegistered = response.data.registered ?? false;
        if (this.isRegistered) {
          try {
            await addListeners();
            await getDeliveredNotifications();
          } catch (error) {
            console.error(error);
          }
        }
        this.registrationCheckComplete = true;
      }
    } finally {
      this.loading = false;
      this.loaded = true;
      this.$store.dispatch("setLoading", false);
    }
  },
  async beforeRouteLeave(to, from) {
    // await $vfm.hideAll();
    eventBus.$off("team-created");
    eventBus.$off("alias-updated");
    eventBus.$off("locations-done");
    eventBus.$off("start");
    return true;
  },
  computed: {
    showRegisterForNotificationsButton() {
      return (
        !this.isRegistered &&
        Capacitor.isNativePlatform() &&
        this.loaded &&
        this.registrationCheckComplete &&
        !this.hideRegisterForNotificationsButton
      );
    },
    showCanceled: {
      get() {
        return this.$store.state.showCanceled;
      },
      set(sc) {
        this.$store.commit("setShowCanceled", sc);
      },
    },
    showNotAttending: {
      get() {
        return this.$store.state.showNotAttending;
      },
      set(na) {
        this.$store.commit("setShowNotAttending", na);
      },
    },
  },
  methods: {
    showHasSeenUpdateUserAliasModal,
    async registerForNotifications() {
      if (!this.isRegistered) {
        try {
          await addListeners();
          await registerNotifications();
          try {await getDeliveredNotifications();} catch (error) {console.error(error);}
          this.$store.dispatch("setIsRegisteredForNotifications", true);
          this.isRegistered = true;
          const toast = useToast();
          toast("You are now registered for notifications", {
            timeout: 2500,
            type: TYPE.DEFAULT,
            hideProgressBar: true,
          });
        } catch (error) {
          console.error(error);
          const response = await UserService.isUserRegisteredForNotifications(
            this.$store.state.userFirebaseUid
          );
          if (response.data.registered) {
            this.$store.dispatch("setIsRegisteredForNotifications", true);
            this.isRegistered = true;
          }
        }
      } else {
        const toast = useToast();
        toast("You are already registered for notifications", {
          timeout: 2500,
          type: TYPE.INFO,
          hideProgressBar: true,
        });
        this.registrationCheckComplete = true;
      }
    },
    updateWidth() {
      this.windowWidth = window.innerWidth;
    },
    dayjs,
    getVoteEndText(d: Date | Dayjs): string {
      const diff = dayjs(d).diff(dayjs(), "hour");
      if (diff < 24) {
        if (diff <= 1) {
          const minuteDiff = dayjs(d).diff(dayjs(), "minute");
          if (minuteDiff < 1) {
            return "in less than a minute";
          } else {
            return "in " + minuteDiff + " minutes";
          }
        }
        return "in " + dayjs(d).diff(dayjs(), "hour") + " hours";
      }
      return dayjs(d).fromNow();
    },
    cancelEditing() {
      this.userAlias = this.$store.state.userAlias.toLowerCase();
      this.editingAlias = false;
    },
    async refresh() {
      this.loading = true;
      try {
        await this.getTeams();
        await this.getLocations();
        await this.getDashboardData();
      } catch (error) {
        if (error) console.error(error);
      } finally {
        this.loading = false;
        this.completeRefresh();
      }
    },
    async editButtonClicked() {
      if (this.editingAlias) {
        // send req to save alias to backend
        const response = await UserService.updateUserAlias(
          this.$store.state.userFirebaseUid,
          this.userAlias.toLowerCase()
        );
        if (response && response.status === 200) {
          const toast = useToast();
          toast(response.data.msg, {
            timeout: 2500,
            type: TYPE.DEFAULT,
            hideProgressBar: true,
          });
          await this.$store.dispatch(
            "setUserAlias",
            this.userAlias.toLowerCase()
          );
          this.userAlias = this.$store.state.userAlias.toLowerCase();
          this.editingAlias = false;
        } else {
          this.editingAlias = true;
        }
      } else {
        this.editingAlias = true;
      }
    },
    // async endLocationWizard() {

    //   this.wizardLocationErrors = await instantiateGuestSessionAndPush();
    // },
    async endEventWizard() {
      this.wizardEventErrors = await instantiateEventAndPush();
    },
    voteEndingLink(e: Event) {
      return { name: "RestaurantSession", params: { eventId: e.eventId } };
    },
    async getLocations(): Promise<void> {
      try {
        this.locations = await getLocations();
      } catch (error) {
        if (error) console.error(error);
      }
    },
    async getTeams(): Promise<void> {
      try {
        if (this.isUserLoggedIn) {
          this.teams = await getTeams();
        }
      } catch (error) {
        if (error) console.error(error);
      }
    },
    async getDashboardData() {
      try {
        this.loaded = false;
        const response = await EventService.getUpcomingEventsAndEndingVotes(
          this.$store.state.userFirebaseUid,
          this.showCanceled,
          this.showNotAttending
        );
        this.upcomingEvents = response.data.upcomingEvents;
        this.votesEndingSoon = response.data.votesEndingSoon;
        this.pastEvents = response.data.pastEvents.sort(
          (a: Event, b: Event) => {
            return dayjs(b.eventStartDateTime).diff(
              dayjs(a.eventStartDateTime)
            );
          }
        );

        this.$store.dispatch(
          "setUserAlias",
          response.data.userAlias.toLowerCase()
        );
      } catch (error) {
        if (error) console.error(error);
      } finally {
        this.loaded = true;
      }
    },
  },
  watch: {
    async showCanceled() {
      this.$store.commit("setLoading", true);
      this.getDashboardData();
      this.$store.commit("setLoading", false);
    },
    async showNotAttending() {
      this.$store.dispatch("setLoading", true);
      this.getDashboardData();
      this.$store.dispatch("setLoading", false);
    },
    "$store.state.userAlias": {
      handler(newValue) {
        this.userAlias = newValue;
      },
      immediate: true, // This ensures the handler is called right away upon the watcher being defined
    },
  },
});
