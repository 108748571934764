import { PushNotifications } from "@capacitor/push-notifications";
import UserService from "./services/UserService";
import store from "./store/store";
import { TYPE, useToast } from "vue-toastification";

export const addListeners = async () => {
  await PushNotifications.addListener("registration", async (token) => {
    await UserService.addNotificationRegistrationToken(
      store.state.userFirebaseUid,
      token.value
    );
  });

  await PushNotifications.addListener("registrationError", (err) => {
    console.error("Registration error: ", err.error);
    const toast = useToast();
    toast("Problem registering user for notifications: " + err.error, {
      timeout: 5000,
      type: TYPE.ERROR,
      hideProgressBar: true,
    });
  });

  await PushNotifications.addListener(
    "pushNotificationReceived",
    (notification) => {
      console.log("Push notification received: ", notification);
    }
  );

  await PushNotifications.addListener(
    "pushNotificationActionPerformed",
    (notification) => {
      console.log(
        "Push notification action performed",
        notification.actionId,
        notification.inputValue
      );
    }
  );
};

export const registerNotifications = async () => {
  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === "prompt") {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== "granted") {
    throw new Error("User denied permissions!");
  }

  await PushNotifications.register();
};

export const getDeliveredNotifications = async () => {
  const notificationList = await PushNotifications.getDeliveredNotifications();
  console.log("delivered notifications", notificationList);
};
