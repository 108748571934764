
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    assignedStep: { type: Number, required: true },
    step: { type: Number, required: true },
    nextDisabled: { type: Boolean, required: true },
    progressLockedOverride: { type: Boolean, required: true },
  },
  emits: ["jump"],
  computed: {
    locked() {
      return (
        this.assignedStep > this.step &&
        (!this.progressLockedOverride || this.assignedStep === this.step)
      );
    },
  },
  methods: {
    jump() {
      if (!this.locked) {
        this.$emit("jump", this.step);
      }
    },
  },
});
