
import { PropType, defineComponent } from "vue";
import { showTeamOverviewModal } from "../Modals/ModalController";
import { Event } from "@/types";
import dayjs, { Dayjs } from "dayjs";
import { event } from "vue-gtag";
import { googleUrl } from "@/common/common";
export default defineComponent({
  data() {
    const canceledEventClass =
      "text-danger text-decoration-line-through text-decoration-underline fw-bolder";
    return { canceledEventClass, showNotAttendingChip: false };
  },
  props: {
    event: {
      type: Object as PropType<Event>,
      required: true,
    },
    typeOfEvent: {
      type: String,
      required: true,
    },
    isNotAttending: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    minutesUntilVoteEnds() {
      return dayjs(this.event.restaurantVoteEndDateTime).diff(
        dayjs(),
        "minute"
      );
    },
    numberOfPeopleWhoHaveVoted() {
      return new Set(this.event?.votes.map((v) => v.userFirebaseUid)).size ?? 0;
    },
    personOrPeopleHasOrHave() {
      return this.numberOfPeopleWhoHaveVoted === 1
        ? "person has"
        : "people have";
    },
    needsVote() {
      return (
        !this.isNotAttending &&
        this.event.votes.filter(
          (v) => v.userFirebaseUid === this.$store.state.userFirebaseUid
        ).length === 0 &&
        !(this.typeOfEvent === "past events") &&
        !this.event.cancelled &&
        !this.event.winningRestaurant
      );
    },
  },
  methods: {
    showTeamOverviewModal,
    dayjs,
    googleUrl,
    getVenueDetailsLink(id: string) {
      return "https://www.gophrr.io/Venue/" + id;
    },
    getVoteEndText(d: Date | Dayjs): string {
      const diff = dayjs(d).diff(dayjs(), "hour");
      if (diff < 24) {
        if (diff < 0) {
          return "";
        }
        if (diff <= 1) {
          const minuteDiff = dayjs(d).diff(dayjs(), "minute");
          if (minuteDiff < 1) {
            return "in less than a minute";
          } else {
            return "in " + minuteDiff + " minutes";
          }
        }
        return "in " + dayjs(d).diff(dayjs(), "hour") + " hours";
      }
      return dayjs(d).fromNow();
    },
  },
});
