
import { PropType, defineComponent } from "vue";
import dayjs, { Dayjs } from "dayjs";
import { Event } from "@/types";
import EventsCardListItem from "./EventsCardListItem.vue";
export default defineComponent({
  props: {
    title: { type: String, required: true },
    loaded: { type: Boolean, required: true },
    events: { type: Array as PropType<Event[]>, required: true },
    typeOfEvent: { type: String, required: true },
  },
  data() {
    return {
      eventsToShow: this.events.slice(0, 3),
    };
  },
  methods: {
    dayjs,
    changeEventsToShow() {
      this.eventsToShow =
        this.eventsToShow.length === this.events.length
          ? this.events.slice(0, 3)
          : this.events;
    },
  },
  watch: {
    events(n, o) {
      this.eventsToShow =
        this.eventsToShow.length === this.events.length
          ? n
          : this.events.slice(0, 3);
    },
  },
  components: {
    EventsCardListItem,
  },
});
