
import { defineComponent, PropType } from "vue";
import WizardProgressBubble from "./WizardProgressBubble.vue";
import { TYPE, useToast } from "vue-toastification";
export default defineComponent({
  beforeMount() {
    this.$router.push({
      name: this.wizardRoutes[0],
    });
  },
  data() {
    return {
      previousRoute: "" as string,
      nextDisabled: true as boolean,
      nextLoading: false as boolean,
      progressLockedOverride: false as boolean,
    };
  },
  props: {
    wizardRoutes: Array,
    skippable: { type: Boolean, default: false },
    finalWord: { type: String, default: "Finish" },
    showProgressBar: { type: Boolean, default: false },
    wizardErrors: { type: String, default: "" },
  },
  watch: {
    wizardErrors(n, o) {
      this.nextLoading = false;
      this.nextDisabled = false;

      this.progressLockedOverride = false;
      const toast = useToast();
      toast(n, {
        timeout: 5000,
        type: TYPE.ERROR,
        hideProgressBar: true,
      });
    },
  },
  computed: {
    totalStepsArray(): Array<number> {
      return Array.from(Array(this.totalSteps).keys());
    },
    nextButtonClass() {
      return this.nextDisabled
        ? "btn btn-outline-primary rounded-pill w-50 p-2 mx-1"
        : "btn btn-primary text-light rounded-pill w-50 p-2 mx-1";
    },
    currentProgress(): number {
      return (this.stepNumber / (this.totalSteps - 1)) * 100;
    },
    currentProgressStyle(): string {
      return "width: " + this.currentProgress + "%;height:1em;";
    },
    stepNumber(): number {
      return this.wizardRoutes.findIndex((w) => this.$route.name.includes(w));
    },
    oneIndexedStepNumber(): number {
      return this.stepNumber + 1;
    },
    totalSteps(): number {
      return this.wizardRoutes.length;
    },
    stepNumberClass: function (s: number): string {
      if (s < this.stepNumber) return "text-primary";
      else if (s === this.stepNumber) return "text-secondary";
      else {
        return "text-light";
      }
    },
  },
  emits: ["end-wizard"],
  methods: {
    jump(s: number): void {
      this.$router.push({ name: this.wizardRoutes[s] });
    },
    enableNextRequested() {
      this.nextDisabled = false;
    },
    disableNextRequested() {
      this.nextDisabled = true;
    },
    // enableBackRequested() {
    //   this.backDisabled = false;
    // },
    backButtonClicked() {
      if (this.stepNumber > 0) {
        this.$router.push({ name: this.wizardRoutes[this.stepNumber - 1] });
      } else {
        this.$router.push({ name: "Home" });
      }
    },
    async nextButtonClicked() {
      if (this.stepNumber < this.wizardRoutes.length - 1) {
        this.$router.push({
          name: this.wizardRoutes[this.stepNumber + 1],
        });
      } else {
        // time to go, Hans...
        this.nextLoading = true;
        this.nextDisabled = true;

        this.progressLockedOverride = true;
        this.endWizardFunction();
      }
      this.nextDisabled = true;
    },
    endWizardFunction() {
      this.$emit("end-wizard");
    },
  },
  components: { WizardProgressBubble },
});
