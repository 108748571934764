import store from "@/store/store";
import router from "@/router/router";
import TeamsService from "@/services/TeamsService";
import dayjs from "dayjs";

export async function instantiateEventAndPush(): Promise<string | undefined> {
  try {
    const d = store.state;
    // TIME IS SENT WITH TIMEZONE OFFSET SO THERE IS NO NEED TO CONVERT IT
    const response = await TeamsService.createEvent(
      d.userFirebaseUid,
      d.eventWizardContext.teamId,
      d.eventWizardContext.coordinates.lat,
      d.eventWizardContext.coordinates.long,
      0 /*offset, see note above*/,
      parseFloat(d.eventWizardContext.radius),
      d.eventWizardContext.eventStartDateTime,
      d.eventWizardContext.restaurantVoteEndDateTime,
      d.eventWizardContext.sessionType,
      d.eventWizardContext.eventName,
      d.eventWizardContext.locationNickname,
      d.eventWizardContext.includeDrinks,
      d.eventWizardContext.includeEvents,
      d.eventWizardContext.includeFood
    );
    if (response.data.msg === undefined) {
      await router.push({
        name: "EventDetails",
        params: {
          eventId: response.data.eventId,
        },
      });
      store.dispatch("setEventWizardContext", null);
    } else {
      return response.data.msg;
    }
  } catch (e) {
    console.log(e);
  }
}
