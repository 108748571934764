import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row justify-content-center" }
const _hoisted_3 = { class: "col-sm-12 col-md-8" }
const _hoisted_4 = { class: "text-center" }
const _hoisted_5 = { class: "d-flex flex-row justify-content-center" }
const _hoisted_6 = ["aria-valuenow"]
const _hoisted_7 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_8 = { class: "d-flex flex-row py-2" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = {
  key: 0,
  class: "spinner-border spinner-border-sm text-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WizardProgressBubble = _resolveComponent("WizardProgressBubble")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totalStepsArray, (s) => {
              return (_openBlock(), _createBlock(_component_WizardProgressBubble, {
                progressLockedOverride: _ctx.progressLockedOverride,
                nextDisabled: _ctx.nextDisabled,
                assignedStep: s,
                step: _ctx.stepNumber,
                key: s,
                onJump: ($event: any) => (_ctx.jump(s))
              }, null, 8, ["progressLockedOverride", "nextDisabled", "assignedStep", "step", "onJump"]))
            }), 128))
          ]),
          (_ctx.showProgressBar)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "progress progress-bar my-4",
                role: "progressbar",
                "aria-label": "Process progress",
                "aria-valuenow": _ctx.currentProgress,
                style: _normalizeStyle(_ctx.currentProgressStyle),
                "aria-valuemin": "0",
                "aria-valuemax": "100"
              }, null, 12, _hoisted_6))
            : _createCommentVNode("", true),
          _createVNode(_component_router_view, {
            onEnableNext: _ctx.enableNextRequested,
            onDisableNext: _ctx.disableNextRequested
          }, null, 8, ["onEnableNext", "onDisableNext"])
        ]),
        (_ctx.wizardErrors !== '')
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.wizardErrors), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("button", {
            class: "btn btn-outline-primary rounded-pill w-50 p-2 mx-1",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.backButtonClicked && _ctx.backButtonClicked(...args)))
          }, " Back"),
          _createElementVNode("button", {
            class: _normalizeClass(_ctx.nextButtonClass),
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.nextButtonClicked && _ctx.nextButtonClicked(...args))),
            disabled: _ctx.nextDisabled
          }, [
            _createTextVNode(_toDisplayString(_ctx.stepNumber === _ctx.totalSteps - 1 ? _ctx.finalWord : "Next") + " ", 1),
            (_ctx.nextLoading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10))
              : _createCommentVNode("", true)
          ], 10, _hoisted_9)
        ])
      ])
    ])
  ]))
}